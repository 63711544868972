@import "./reset.scss";
@import "./variables.scss";

html,
body,
#root {
  font-family: "Roboto", sans-serif;
  width: 100%;
  height: 100%;
  overflow: overlay;
  white-space: pre-line;
}

.ReactModal__Overlay {
  z-index: 1000;
}

* {
  box-sizing: border-box;
}
